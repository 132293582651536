<template>
  <div style="position: relative; width:100%" >
    <svg id="histogram"></svg>
  </div>
</template>
<script>
import * as d3 from "d3";
export default {
  props: {
    cases: {
      default() {
        return []
      },
      type: Array,
    },
    xLabel: {
      type: String
    },
    mapFunction: {
      type: Function
    },
    xMax: {
      type: Number
    }
  },
  data() {
    return {
    }
  },
  watch: {
    cases() {
      this.render()
    }
  },
  mounted() {
    this.render()
  },
  methods: {
    render() {
      // set the dimensions and margins of the graph
      const margin = {top: 20, right: 30, bottom: 40, left: 40}
      const xAxisOffset = 10
      const width = 700 - margin.left - margin.right
      const height = 350 - margin.top - margin.bottom

      // append the svg object to the body of the page
      d3.select("#histogram").selectAll("*").remove()
      const svg = d3.select("#histogram")
          .attr("viewBox", [0, 0, width + margin.left + margin.right, height + margin.top + margin.bottom])
          .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
          .append("g")
          .attr("transform",
              `translate(${margin.left},${margin.top})`);

      // X axis: scale and draw:
      const x = d3.scaleLinear()
          .domain([0, this.xMax])
          .range([xAxisOffset, width-xAxisOffset])
      svg.append("g")
          .attr("transform", `translate(0, ${height})`)
          .call(d3.axisBottom(x).ticks([5]))

      // set the parameters for the histogram
      const histogram = d3.histogram()
          .value(this.mapFunction)   // I need to give the vector of value
          .domain(x.domain())  // then the domain of the graphic
          .thresholds(d3.range(0, this.xMax, 5)); // then the numbers of bins

      // And apply this function to data to get the bins
      const zeroSluCount = this.cases.filter(aCase => aCase.STS_Normo_SLU == 0).length
      const bins = histogram(this.cases.filter(aCase => aCase.STS_Normo_SLU > 0));

      // Y axis: scale and draw:
      const y = d3.scaleLinear()
          .range([height, 0]);
      const yMax = Math.max(10, d3.max(bins, function(d) { return d.length; }), zeroSluCount)
      y.domain([0, yMax]);   // d3.hist has to be called before the Y axis obviously
      svg.append("g")
          .call(d3.axisLeft(y))
          .call(g => g.append("text")
              .attr("x", -margin.left)
              .attr("y", -margin.top/2)
              .attr("fill", "currentColor")
              .attr("text-anchor", "start")
              .text("Number of cases"));

      // append the bar rectangles to the svg element
      svg.selectAll("rect")
          .data(bins)
          .join("rect")
          .attr("x", 1)
          .attr("transform", function(d) { return `translate(${x(d.x0)} , ${y(d.length)})`})
          .attr("width", function(d) { return x(d.x1) - x(d.x0) -1})
          .attr("height", function(d) { return height - y(d.length); })
          .style("fill", d => {
            return d.x1 > 0 ? "#440154" : "#c8c7f1"
          })

      svg
          .append('defs')
          .append('marker')
          .attr('id', 'arrow')
          .attr('viewBox', [0, 0, 10, 10])
          .attr('refX', 10)
          .attr('refY', 5)
          .attr('markerWidth', 10)
          .attr('markerHeight', 10)
          .attr('orient', 'auto-start-reverse')
          .append('path')
          .attr('d', d3.line()([[0, 0], [0, 10], [10, 5]]))
          .attr('fill', "#440154");

      svg
          .append('path')
          .attr('d', d3.line()([[x(0), y(zeroSluCount)], [x(0), y(0)]]))
          .attr('stroke', "#440154")
          .attr('marker-start', 'url(#arrow)')
          .attr('fill', 'none');

      // X axis label:
      svg.append("text")
          .attr("x", width/2)
          .attr("y", height + 30)
          .attr("text-anchor", "middle")
          .attr("fill", "currentColor")
          .text(this.xLabel);

      // Add brushing (selection rectangle)
      svg
        .call(d3.brushX()
          .extent([ [0,0], [width,height] ])
          .on("start end", event => this.updateChart(event, x))
        )
    },
    updateChart(event, xScale) {
      let selectedCasesMinDuration = null
      let selectedCasesMaxDuration = null
      if (event.selection) {
        selectedCasesMinDuration = xScale.invert(event.selection[0])
        selectedCasesMaxDuration = xScale.invert(event.selection[1])
      }
      this.$emit("selectionChanged", selectedCasesMinDuration, selectedCasesMaxDuration)
    }
  }
};
</script>

<style scoped>
</style>